import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export class DateValidators {
  static greaterThan(startControl: AbstractControl): ValidatorFn {
    return (endControl: AbstractControl): ValidationErrors | null => {
      if (!startControl.value || !endControl.value) return null;
      const startDate: number = Math.trunc(new Date(startControl.value).valueOf() / (60 * 1000));
      const endDate: number = Math.trunc(new Date(endControl.value).valueOf() / (60 * 1000));
      if (!startDate || !endDate) {
        return null;
      }
      if (startDate >= endDate) {
        return {greaterThan: true};
      }
      return null;
    };
  }

  static lessThan(startControl: AbstractControl): ValidatorFn {
    return (endControl: AbstractControl): ValidationErrors | null => {
      if (!startControl.value || !endControl.value) return null;
      const startDate: number = Math.trunc(new Date(startControl.value).valueOf() / (60 * 1000));
      const endDate: number = Math.trunc(new Date(endControl.value).valueOf() / (60 * 1000));
      if (!startDate || !endDate) {
        return null;
      }
      if (startDate <= endDate) {
        return {lessThan: true};
      }
      return null;
    };
  }

}

export class PasswordValidators {
  static match: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const passwordControl = control.get('newPassword');
    const confirmPasswordControl = control.get('confirmPassword');
    if (!passwordControl?.value || !confirmPasswordControl?.value) return null;
    // Check if both controls exist and their values match
    if (passwordControl && confirmPasswordControl && passwordControl.value !== confirmPasswordControl.value) {
      // Return a validation error
      return {passwordMismatch: true};
    }

    // Return null if there's no validation error
    return null;
  };

  static validatorCharacters: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;
    if (!value) {
      return null; // Return null if no value is provided (valid)
    }

    const lowerCaseRegex = /[a-z]/;
    const upperCaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharacterRegex = /[^a-zA-Z0-9]/;

    const hasLowerCase = lowerCaseRegex.test(value);
    const hasUpperCase = upperCaseRegex.test(value);
    const hasNumber = numberRegex.test(value);
    const hasSpecialCharacter = specialCharacterRegex.test(value);

    const isValid = hasLowerCase && hasUpperCase && hasNumber && hasSpecialCharacter;
    return isValid ? null : {'invalidPassword': true};
  };
}
